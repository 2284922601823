import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Autocomplete, TextField } from '@mui/material'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import { setActiveInstitution } from '../../../../app/modules/auth/core/_requests'
import { useInstitution } from '../../../../app/modules/auth/core/InstitutionContext'
import { usePeriod } from '../../../../app/modules/auth/core/PeriodContext'

const HeaderUserMenu: FC = () => {
  const { currentUser, auth, logout } = useAuth()
  const { institutions, selectedInstitution, setSelectedInstitution, setInstitutions } = useInstitution()
  const { selectedPeriod, setSelectedPeriod } = usePeriod()

  useEffect(() => {
    if (auth?.data?.user_institutions) {
      setInstitutions(auth.data.user_institutions)
    }
  }, [auth, setInstitutions])

  const handleInstitutionChange = async (event: any, newValue: any) => {
    const selectedId = newValue?.id
    const institution = institutions.find(inst => inst.id === selectedId)

    setSelectedInstitution(institution ?? null)

    if (institution && institution.id) {
      try {
        await setActiveInstitution(institution.id)
        // go to home
        window.location.href = '/'
      } catch (error) {
        console.error('Error setting active institution:', error)
      }
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('logo/escudo.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name} {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2 border-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/administration/security/change-password'} className='menu-link px-5'>
          Cambio de Contraseña
        </Link>
      </div>

      <div className='menu-item px-5'>
        {/* show select if institutions > 1 */}
        {institutions.length > 1 && (
          <>
            <div className='separator my-2 border-2'></div>
            <div className='py-2 px-4'>
              <label htmlFor='institution-select' className='mb-2 text-gray-600'>
                Cambiar de Institución
              </label>
              <Autocomplete
                id='institution-select'
                options={institutions}
                getOptionLabel={(option) => option.name || ''}
                value={selectedInstitution || null}
                onChange={handleInstitutionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder='Buscar Institución'
                  />
                )}
              />

            </div>
          </>
        )}
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5 text-danger'>
          Cerrar Sesión
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
