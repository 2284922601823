import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { PersonRounded } from '@mui/icons-material';
import { Button} from '@mui/material'
import { styled } from '@mui/system';


const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const RoundedButton = styled(Button)({
    borderRadius: '50%',
    padding: '10px',
    minWidth: 'auto',
    marginTop: '10px',
  });
  return (
    <div className='app-navbar flex-shrink-0 mx-10'>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <RoundedButton
            aria-label='edit'
            title='Sesión de usuario'
            size='large'
            color='primary'
            variant='contained'
          >
            <PersonRounded fontSize='inherit' sx={{fontSize: '1.5rem'}}/>
          </RoundedButton>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
