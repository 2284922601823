import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react'
import { UserInstitutionModel } from './_models'
import { getInstitutionAccess } from './_requests'
import { useAuth } from './Auth'

interface InstitutionContextProps {

  institutions: UserInstitutionModel[]
  setInstitutions: ( institutions: UserInstitutionModel[] ) => void

  setSelectedInstitution: ( institution: UserInstitutionModel | null ) => void
  selectedInstitution: UserInstitutionModel | null

}

const InstitutionContext = createContext<InstitutionContextProps | undefined>( undefined )

export const useInstitution = () => {
  const context = useContext( InstitutionContext )
  if (!context) {
    throw new Error('useInstitution must be used within an InstitutionProvider')
  }
  return context
}

export const InstitutionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const { auth } = useAuth()
  const [ institutions, setInstitutions ] = useState<UserInstitutionModel[]>( [] )
  
  const [ selectedInstitution, setSelectedInstitution ] = useState<UserInstitutionModel | null>( null )
  const [ institutionsLoaded, setInstitutionsLoaded ] = useState( false )

  useEffect( () => {
    
    const loadInstitutions = async () => {

      if ( auth?.data ) {

        try {

          const institutions = await getInstitutionAccess()

          if ( institutions && institutions.data ) {

            const activeInstitution = institutions.data.find( institution => institution.active )
            setSelectedInstitution( activeInstitution || null )
            setInstitutionsLoaded( true )
          }
        } catch ( error ) {
          console.error('Error fetching institutions:', error)
        }
      }
    }
    loadInstitutions()
  }, [ auth, institutionsLoaded ])

  return (
    <InstitutionContext.Provider value={{ institutions, selectedInstitution, setSelectedInstitution, setInstitutions }}>
      {children}
    </InstitutionContext.Provider>
  )
}
