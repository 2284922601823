import {Delete, Edit} from '@mui/icons-material'
import {Box, Button, CircularProgress, IconButton, Typography} from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {useEffect, useState} from 'react'
import RegisterModal from './components/RegisterModal'
import Snack from '../../shared/components/Snack'
import {getPublicPolicies} from './_requests'
import {PublicPolicyData} from './_models'
import DeleteDialog from './components/DeleteDialog'
import {hasPermission} from '../../auth'
const apiPublicPolicies = async ({
  page,
  perPage = 10,
  sortBy = 'id',
  sortDirection = 'asc' as 'asc' | 'desc',
  search = '',
}): Promise<{data: PublicPolicyData[]; total: number}> => {
  try {
    const registers = await getPublicPolicies({page, perPage, sortBy, sortDirection, search})
    if (registers && Array.isArray(registers.data)) {
      return {data: registers.data, total: registers.total}
    } else {
      console.error('Expected an array but received:', registers)
      return {data: [], total: 0}
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {data: [], total: 0}
  }
}

const PublicPolicyIndex = () => {
  const [data, setData] = useState<any[]>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 10})
  const [totalRows, setTotalRows] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [isRefetching, setIsRefetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>('')

  //register modal
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false)

  //snack
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info' | 'warning'>(
    'success'
  )

  const [selectedRegister, setSelectedRegister] = useState(null)

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const handleOpenDeleteDialog = (register: any) => {
    setSelectedRegister(register)
    setIsDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setSelectedRegister(null)
    setIsDeleteDialogOpen(false)
  }

  const handleRegisterModalOpen = (register: any) => {
    // setSelectedCity(city);
    setSelectedRegister(register)
    setIsRegisterModalOpen(true)
  }

  const handleRegisterModalClose = () => {
    setIsRegisterModalOpen(false)
  }
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }
  const handleOpenSnackbar = (
    message: string,
    type: 'success' | 'error' | 'info' | 'warning'
  ): void => {
    setSnackbarMessage(message)
    setSnackbarType(type)
    setSnackbarOpen(true)
  }
  const fetchRegisters = async () => {
    if (!data.length && search === '') {
      setLoading(true)
    } else {
      setIsRefetching(true)
    }
    try {
      const result = await apiPublicPolicies({
        page: pagination.pageIndex + 1,
        perPage: pagination.pageSize,
        search,
      })
      setData(result.data)
      setTotalRows(result.total)
      setError(null)
      setIsRefetching(false)
    } catch (error) {
      setError('Error fetching data')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchRegisters()
  }, [pagination.pageIndex, pagination.pageSize, search])
  const columns: MRT_ColumnDef<any>[] = [
    {accessorKey: 'institution.name', header: 'Institucion'},
    {accessorKey: 'name', header: 'Politica Pública'},
    {accessorKey: 'cabinet.name', header: 'Gabinete o Sector'},
    {accessorKey: 'type.name', header: 'Tipo politica'},
    {
      id: 'actions',
      header: 'Acciones',
      size: 80,
      Cell: ({row}) => (
        <div className='d-flex justify-content-start gap-2'>
          {hasPermission('publicPolicies-write') && (
            <IconButton
              aria-label='edit'
              onClick={() => handleRegisterModalOpen(row.original)}
              title='Editar'
              size='large'
              color='info'
            >
              <Edit fontSize='inherit' />
            </IconButton>
          )}
          {hasPermission('publicPolicies-write') && (
            <IconButton
              aria-label='delete'
              onClick={() => handleOpenDeleteDialog(row.original)}
              title='Eliminar'
              size='large'
              color='error'
            >
              <Delete fontSize='inherit' />
            </IconButton>
          )}
        </div>
      ),
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data,
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: totalRows,
    enablePagination: true,
    manualFiltering: true,
    onGlobalFilterChange: setSearch,
    state: {globalFilter: search, isLoading: loading, showProgressBars: isRefetching, pagination},
  })
  return (
    <>
      <div className='d-flex justify-content-end align-items-center mb-5 w-100'>
        {hasPermission('publicPolicies-write') && (
          <Button variant='contained' color='primary' onClick={() => handleRegisterModalOpen(null)}>
            Agregar politica
          </Button>
        )}
      </div>
      <Box sx={{width: '100%'}}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color='error'>{error}</Typography>
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>

      <RegisterModal
        fetchRegisters={fetchRegisters}
        isOpen={isRegisterModalOpen}
        selectedRegister={selectedRegister}
        onClose={handleRegisterModalClose}
        openSnackbar={handleOpenSnackbar}
      />
      <Snack
        isOpen={snackbarOpen}
        onClose={handleCloseSnackbar}
        snackbarType={snackbarType}
        snackbarMessage={snackbarMessage}
      />

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        selectedRegister={selectedRegister}
        onClose={handleCloseDeleteDialog}
        fetchRegisters={fetchRegisters}
        openSnackbar={handleOpenSnackbar}
      />
    </>
  )
}

export default PublicPolicyIndex
