import {useEffect, useState} from 'react'
import {PublicPolicyData} from '../_models'
import {deletePublicPolicy} from '../_requests'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface DeleteDialogProps {
  isOpen: boolean
  selectedRegister: PublicPolicyData | null
  onClose: () => void
  fetchRegisters: () => void
  openSnackbar: (message: string, type: 'success' | 'error' | 'info' | 'warning') => void
}
const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  selectedRegister,
  onClose,
  fetchRegisters,
  openSnackbar,
}) => {
  // States ------------------------------------------------------------------------------------------
  const [register, setRegister] = useState<PublicPolicyData>({
    id: null,
    name: '',
    institution_id: '',
    cabinet_id: '',
    policy_type_id: '',
    cost: '',
    policy_state_id: '',
  })

  // Update the department state when the selected department changes
  useEffect(() => {
    if (selectedRegister) {
      setRegister(selectedRegister)
    } else {
      setRegister({
        id: null,
        name: '',
        institution_id: '',
        cabinet_id: '',
        policy_type_id: '',
        cost: '',
        policy_state_id: '',
      })
    }
  }, [selectedRegister])

  // Handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setRegister({...register, [name]: value})
  }

  const handleConfirmDelete = async () => {
    if (selectedRegister && selectedRegister.id) {
      try {
        await deletePublicPolicy(selectedRegister.id)
        fetchRegisters()
        openSnackbar('Registro borrado con éxito', 'success')
      } catch (errors) {
        const message =
          (errors as {response?: {data?: {message?: string}}})?.response?.data?.message ||
          'Ha ocurrido un error'
        openSnackbar(message, 'error')
      }
    }
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Confirmar Borrado'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p className='fw-bold'>
            ¿Esta seguro que desea eliminar este registro de politica pública?
          </p>
          Nombre: {selectedRegister?.name}
          {/* <p>Esto afectara a los registros que pertencen a este municipio</p> */}
          <p className='fw-bold'>Esta acción no se puede deshacer.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleConfirmDelete} color='primary' autoFocus>
          Borrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
