import React from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { App } from './App';

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'normal',
          color: '#AAA',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '20px 0',
          borderBottom: '0.1px solid #b5b5c3',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#68D2DF',
      light: '#EBF8F9',
      dark: '#477C87',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#b5b5c3',
      light: '#f1f1f2',
      dark: '#252f4a',
      contrastText: '#FFF',
    },
    success: {
      main: '#16a34a',
      light: '#ccf2d9',
      dark: '#1c3238',
      contrastText: '#FFF',
    },
    error: {
      main: '#f1416c',
      light: '#FFF5f8',
      dark: '#3a2434',
      contrastText: '#FFF',
    },
    warning: {
      main: '#ffc700',
      light: '#FFF8dd',
      dark: '#392f28',
      contrastText: '#FFF',
    },
    info: {
      main: '#1d4ed8',
      light: '#d6e4ff',
      dark: '#0f2558',
      contrastText: '#FFF',
    },
    // text: {
    //   primary: '#333',
    //   secondary: '#666'
    // }
  },
});

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}

export default Root;
