import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InstitutionProvider } from './modules/auth/core/InstitutionContext'
import { PeriodProvider } from './modules/auth/core/PeriodContext'

const App = () => {
  const queryClient = new QueryClient()
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
          <InstitutionProvider>
          <PeriodProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </PeriodProvider>
          </InstitutionProvider>
            </QueryClientProvider>
            </LocalizationProvider>;
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
