// MasterLayout.tsx
import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { PageDataProvider, usePageData } from './core'
import { reInitMenu } from '../helpers'

import { Typography, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const MasterLayoutContent = () => {
  const location = useLocation()
  const [marginTop, setMarginTop] = useState('5em')
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData()

  const handleResize = () => {
    if (window.innerWidth >= 350) {
      setMarginTop('10em')
    } else {
      setMarginTop('10em')
    }
  }

  useEffect(() => {
    reInitMenu()
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [location.key])

  return (
    <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
      <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
        <HeaderWrapper />
        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
          <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
              <div style={{ marginTop }}>
              {
                ( pageTitle || pageDescription || ( Array.isArray( pageBreadcrumbs ) && pageBreadcrumbs.length > 0 ) ) &&

                <div className='w-100 border-bottom' style={{padding:'0px 35px 0px 35px'}}>

                  <div className='d-flex flex-column-fluid'>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} style={{ marginTop: '16px' }}>
                    {
                      pageBreadcrumbs?.map((item, index) => (
                      <Link key={index} color='inherit' href={item.path}>
                        {item.title}
                      </Link>
                    ))}
                    </Breadcrumbs>
                  </div>

                  <Typography
                    variant='h3'
                    component='h3'
                    color={'primary'}
                    fontWeight={'bold'}
                    mt={2}
                    fontSize={'2.25em'}
                  >
                    {pageTitle}
                  </Typography>

                  <Typography
                    variant='subtitle1'
                    component='p'
                    color={''}
                    mt={1}
                    mb={2}
                    fontSize={'1.125em'}
                  >
                    {pageDescription}
                  </Typography>
                  
                </div>
              }
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div>
  )
}

const MasterLayout = () => {
  return (
    <PageDataProvider>
      <MasterLayoutContent />
    </PageDataProvider>
  )
}

export { MasterLayout }
