import {Box, CircularProgress, IconButton, Typography} from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {useEffect, useState} from 'react'
import {getReport} from './_requests'
const apiPublicPolicies = async ({
  page,
  perPage = 10,
  sortBy = 'id',
  sortDirection = 'asc' as 'asc' | 'desc',
  search = '',
}): Promise<{data: any; columns: Array<string>}> => {
  try {
    const registers = await getReport({page, perPage, sortBy, sortDirection, search})
    if (registers && Array.isArray(registers.data)) {
      return registers
    } else {
      console.error('Expected an array but received:', registers)
      return {data: [], columns: []}
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {data: [], columns: []}
  }
}

const Report = () => {
  const [data, setData] = useState<any[]>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 10})
  const [totalRows, setTotalRows] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [isRefetching, setIsRefetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>('')

  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([])
  // let columns: MRT_ColumnDef<any>[] = [
  //   {accessorKey: 'institution.name', header: 'Institucion'},
  //   {accessorKey: 'name', header: 'Politica Pública'},
  //   {accessorKey: 'cabinet.name', header: 'Gabinete o Sector'},
  //   {accessorKey: 'type.name', header: 'Tipo politica'},
  // ]

  const fetchRegisters = async () => {
    if (!data.length && search === '') {
      setLoading(true)
    } else {
      setIsRefetching(true)
    }
    try {
      const result = await apiPublicPolicies({
        page: pagination.pageIndex + 1,
        perPage: pagination.pageSize,
        search,
      })

      setColumns(
        result.columns.map((c) => {
          return {
            accessorKey: c,
            header: c,
          }
        })
      )

      setData(result.data)
      // setTotalRows(result.total)
      // setError(null)
      // setIsRefetching(false)
    } catch (error) {
      setError('Error fetching data')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRegisters()
  }, [pagination.pageIndex, pagination.pageSize, search])

  const table = useMaterialReactTable({
    columns,
    data,
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: totalRows,
    enablePagination: true,
    manualFiltering: true,
    onGlobalFilterChange: setSearch,
    state: {globalFilter: search, isLoading: loading, showProgressBars: isRefetching, pagination},
  })

  return (
    <>
      <Box sx={{width: '100%'}}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color='error'>{error}</Typography>
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
    </>
  )
}
export default Report
