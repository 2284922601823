import React from 'react';
import { Snackbar, Alert} from '@mui/material';

interface SnackProps {
  isOpen: boolean;
  onClose: () => void;
  snackbarType: 'success' | 'error' | 'info' | 'warning';
  snackbarMessage: string;
}

const Snack: React.FC<SnackProps> = ({
  isOpen,
  onClose,
  snackbarType,
  snackbarMessage
}) => {


  return (
    <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={onClose}
          severity={snackbarType}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
  );
};

export default Snack;
