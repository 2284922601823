import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { getActivePlanningPeriod } from './_requests'
import { useAuth } from './Auth'

interface PeriodContextProps {
  selectedPeriod: any | null
  setSelectedPeriod: (period: any | null) => void
}

const PeriodContext = createContext<PeriodContextProps | undefined>(undefined)

export const usePeriod = () => {
  const context = useContext(PeriodContext)
  if (!context) {
    throw new Error('usePeriod must be used within a PeriodProvider')
  }
  return context
}

export const PeriodProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<any | null>(null)
  const { auth } = useAuth()

  useEffect(() => {
    const loadPeriod = async () => {
      if (auth?.data) {
        try {
          const period = await getActivePlanningPeriod()
          if (period && period.data && period.data.id)
          setSelectedPeriod(period.data)
        } catch (error) {
          console.error('Error fetching active planning period:', error)
        }
      }
    }
    loadPeriod()
  }, [auth])

  return (
    <PeriodContext.Provider value={{ selectedPeriod, setSelectedPeriod }}>
      {children}
    </PeriodContext.Provider>
  )
}
