import axios from 'axios'
import {
  DepartmentModel,
  CityModel,
  InstitutionModel,
  PlanningPeriodModel,
  MeasurementUnitModel,
  MunicipalDevelopmentCategoryModel,
  MunicipalDevelopmentSubcategoryModel,
  CurrencyModel,
  FinancialEntityModel,
  FundingSourceModel
} from './_models'
import { Cabinet, PublicPolicyState, PublicPolicyType } from '../../publicPolicy/pages/_models'

const API_URL = process.env.REACT_APP_API_URL

const ADMINISTRATION_URL = `${API_URL}/administration`
const DEPARTMENTS_URL = `${ADMINISTRATION_URL}/departament`
const CITIES_URL = `${ADMINISTRATION_URL}/city`
const INSTITUTIONS_URL = `${ADMINISTRATION_URL}/institution`
const PLANNING_PERIODS_URL = `${ADMINISTRATION_URL}/planning-period`
const MEASUREMENT_UNITS_URL = `${ADMINISTRATION_URL}/unit-measurement`
const MUNICIPAL_DEVELOPMENT_CATEGORIES_URL = `${API_URL}/municipal-development/categories`
const MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL = `/subcategories`
const CURRENCY_URL = `${API_URL}/currency`
const FINANCIAL_ENTITY_URL = `${API_URL}/funding/source`
const FUNDING_SOURCE_URL = `${API_URL}/financial/entity`

const CABINET_URL = `${ADMINISTRATION_URL}/cabinet`
const PUBLIC_POLICY_TYPE_URL = `${ADMINISTRATION_URL}/public-policy-type`
const PUBLIC_POLICY_STATE_URL = `${ADMINISTRATION_URL}/public-policy-state`;

const SIGNERS_URL = `${API_URL}/signers`

// Signers endpoints -------------------------------------------------------------------------------

export function getSigners() {
  return axios.get<any>(SIGNERS_URL)
}

export function getSignerTypes() {
  return axios.get<any>(`${SIGNERS_URL}/types`)
}

export function storeSigner(data: any) {
  return axios.post<any>(SIGNERS_URL, data)
}


export function deleteSigner(id: number) {
  return axios.delete<any>(`${SIGNERS_URL}/${id}`)
}



// Municipal Development Categories endpoints ------------------------------------------------------

export function getMunicipalDevelopmentCategories() {
  return axios.get<MunicipalDevelopmentCategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL)
}

export function getMunicipalDevelopmentCategory(id: number) {
  return axios.get<MunicipalDevelopmentCategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + id)
}

export function createMunicipalDevelopmentCategory(name: string, description: string, is_active: boolean | null | undefined, order: number | null | undefined) {
  return axios.post<MunicipalDevelopmentCategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL, { name, description, is_active, order })
}

export function updateMunicipalDevelopmentCategory(id: number, name: string, description: string, is_active: boolean | null | undefined, order: number | null | undefined) {
  return axios.put<MunicipalDevelopmentCategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + id, { name, description, is_active, order })
}

export function deleteMunicipalDevelopmentCategory(id: number) {
  return axios.delete<MunicipalDevelopmentCategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + id)
}

// Municipal Development Subcategories endpoints ---------------------------------------------------

export function getMunicipalDevelopmentSubcategories(category_id: number) {
  return axios.get<MunicipalDevelopmentSubcategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + category_id + MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL)
}

export function getMunicipalDevelopmentSubcategory(category_id: number, subcategory_id: number) {
  return axios.get<MunicipalDevelopmentSubcategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + category_id + MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL + '/' + subcategory_id)
}

export function createMunicipalDevelopmentSubcategory(category_id: number, name: string, description: string, is_active: boolean | null | undefined, order: number | null | undefined) {
  return axios.post<MunicipalDevelopmentSubcategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + category_id + MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL, { name, description, is_active, order })
}

export function updateMunicipalDevelopmentSubcategory(category_id: number, subcategory_id: number, name: string, description: string, is_active: boolean | null | undefined, order: number | null | undefined) {
  return axios.put<MunicipalDevelopmentSubcategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + category_id + MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL + '/' + subcategory_id, { name, description, is_active, order })
}

export function deleteMunicipalDevelopmentSubcategory(category_id: number, subcategory_id: number) {
  return axios.delete<MunicipalDevelopmentSubcategoryModel>(MUNICIPAL_DEVELOPMENT_CATEGORIES_URL + '/' + category_id + MUNICIPAL_DEVELOPMENT_SUBCATEGORIES_URL + '/' + subcategory_id)
}

// Department endpoints ----------------------------------------------------------------------------

export function getDepartments() {
  return axios.get<DepartmentModel>(DEPARTMENTS_URL + '/index')
}

export function createDepartment(name: string) {
  return axios.post<DepartmentModel>(DEPARTMENTS_URL, { name })
}

export function updateDepartment(id: number, name: string) {
  return axios.put<DepartmentModel>(DEPARTMENTS_URL + '/update', { id, name })
}

export function deleteDepartment(id: number) {
  return axios.delete<DepartmentModel>(DEPARTMENTS_URL + '/delete/' + id)
}

// Cities endpoints --------------------------------------------------------------------------------

export function getCities() {
  return axios.get<CityModel>(CITIES_URL + '/index')
}

export function createCity(name: string, department_id: number, geo_reference: string | null) {
  return axios.post<CityModel>(CITIES_URL, { name, departament_id: department_id, geo_reference })
}

export function updateCity(id: number, name: string, department_id: number, geo_reference: string | null) {
  return axios.put<CityModel>(CITIES_URL + '/update', { id, name, departament_id: department_id, geo_reference })
}

export function deleteCity(id: number) {
  return axios.delete<CityModel>(CITIES_URL + '/delete/' + id)
}

// Institutions endpoints --------------------------------------------------------------------------

export function getInstitutions() {
  return axios.get<InstitutionModel>(INSTITUTIONS_URL + '/index')
}

export function getAllInstitutions() {
  return axios.get<InstitutionModel[]>(`${API_URL}/institution`)
}

// Test with console logging
export function getUserInstitutions(is_municipality?: boolean | null) {
  let queryString = '';
  if (is_municipality !== undefined && is_municipality !== null) {
    queryString = `?is_municipality=${is_municipality}`;
  }
  console.log('Query String:', queryString);
  return axios.get<InstitutionModel[]>(`${API_URL}/administration/institution/index-for-user${queryString}`);
}

export function getInstitution(id: number) {
  return axios.get<InstitutionModel>(INSTITUTIONS_URL + '/show/' + id)
}

export function createInstitution(name: string, is_municipality: boolean) {
  return axios.post<InstitutionModel>(INSTITUTIONS_URL, { name, is_municipality })
}

export function updateInstitution(id: number, name: string, is_municipality: boolean) {
  return axios.put<InstitutionModel>(INSTITUTIONS_URL + '/update', { id, name, is_municipality })
}

export function deleteInstitution(id: number) {
  return axios.delete<InstitutionModel>(INSTITUTIONS_URL + '/delete/' + id)
}

// Planning Periods endpoints ----------------------------------------------------------------------

export function getPlanningPeriods() {
  return axios.get<PlanningPeriodModel>(PLANNING_PERIODS_URL + '/index')
}

export function createPlanningPeriod(start_date: number | null, end_date: number | null, active: boolean) {
  return axios.post<PlanningPeriodModel>(PLANNING_PERIODS_URL, { start_date, end_date, active })
}

export function updatePlanningPeriod(id: number, start_date: number | null, end_date: number | null, active: boolean) {
  return axios.put<PlanningPeriodModel>(PLANNING_PERIODS_URL + '/update', { id, start_date, end_date, active })
}

export function deletePlanningPeriod(id: number) {
  return axios.delete<PlanningPeriodModel>(PLANNING_PERIODS_URL + '/delete/' + id)
}


// Measurement Units endpoints ---------------------------------------------------------------------

export function getMeasurementUnits() {
  return axios.get<MeasurementUnitModel>(MEASUREMENT_UNITS_URL + '/index')
}

export function createMeasurementUnit(name: string) {
  return axios.post<MeasurementUnitModel>(MEASUREMENT_UNITS_URL, { name })
}

export function updateMeasurementUnit(id: number, name: string) {
  return axios.put<MeasurementUnitModel>(MEASUREMENT_UNITS_URL + '/update', { id, name })
}

export function deleteMeasurementUnit(id: number) {
  return axios.delete<MeasurementUnitModel>(MEASUREMENT_UNITS_URL + '/delete/' + id)
}

// Currencies
export function getCurrencies() {
  return axios.get<any>(`${CURRENCY_URL}/index`);
}

export function saveCurrency(data: CurrencyModel) {

  if (data.id)
    return axios.put<any>(`${CURRENCY_URL}/update`, data);

  return axios.post<any>(`${CURRENCY_URL}/store`, data);
}

export function deleteCurrency(id: number) {
  return axios.delete<any>(`${CURRENCY_URL}/delete/${id}`);
}

// Financial entities
export function getFinancialEntitiess() {
  return axios.get<any>(`${FINANCIAL_ENTITY_URL}/index`);
}

export function saveFinancialEntity(data: FinancialEntityModel) {

  if (data.id)
    return axios.put<any>(`${FINANCIAL_ENTITY_URL}/update`, data);

  return axios.post<any>(`${FINANCIAL_ENTITY_URL}/store`, data);
}

export function deleteFinancialEntity(id: number) {
  return axios.delete<any>(`${FINANCIAL_ENTITY_URL}/delete/${id}`);
}

// Funding sources
export function getFundingSources() {
  return axios.get<any>(`${FUNDING_SOURCE_URL}/index`);
}

export function saveFundingSource(data: FundingSourceModel) {

  if (data.id)
    return axios.put<any>(`${FUNDING_SOURCE_URL}/update`, data);

  return axios.post<any>(`${FUNDING_SOURCE_URL}/store`, data);
}

export function deleteFundingSource(id: number) {
  return axios.delete<any>(`${FUNDING_SOURCE_URL}/delete/${id}`);
}

export function createCabinet(name: string) {
  return axios.post<Cabinet>(CABINET_URL, { name })
}

export function updateCabinet(id: number, name: string) {
  return axios.put<Cabinet>(CABINET_URL + '/update', { id, name })
}

export function deleteCabinet(id: number) {
  return axios.delete<Cabinet>(CABINET_URL + '/delete/' + id)
}


export function createPublicPolicyType(name: string) {
  return axios.post<PublicPolicyType>(PUBLIC_POLICY_TYPE_URL, { name })
}

export function updatePublicPolicyType(id: number, name: string) {
  return axios.put<PublicPolicyType>(PUBLIC_POLICY_TYPE_URL + '/update', { id, name })
}

export function deletePublicPolicyType(id: number) {
  return axios.delete<PublicPolicyType>(PUBLIC_POLICY_TYPE_URL + '/delete/' + id)
}


export function createPublicPolicyState(name: string) {
  return axios.post<PublicPolicyState>(PUBLIC_POLICY_STATE_URL, { name })
}

export function updatePublicPolicyState(id: number, name: string) {
  return axios.put<PublicPolicyState>(PUBLIC_POLICY_STATE_URL + '/update', { id, name })
}

export function deletePublicPolicyState(id: number) {
  return axios.delete<PublicPolicyState>(PUBLIC_POLICY_STATE_URL + '/delete/' + id)
}