export function objectToFormData(obj: any, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData()

  for (let property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if (obj[property] instanceof Date) {
      formData.append(formKey, obj[property].toISOString())
    } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      
        // Handle nested objects
      objectToFormData(obj[property], formData, formKey)
    } else {
      formData.append(formKey, obj[property])
    }
  }

  return formData
}

export function getIdArray(objects: any | null): number[] {
  // Check if the array is null or empty, return an empty array if so
  if (!objects || objects.length === 0) {
    return [];
  }

  // Map through the array and extract the id from each object
  return objects.map(obj => obj.id);
}

export function getFileExtension(contentType: string): string {
  const extensionMap: { [key: string]: string } = {
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/docx': '.docx',
    'application/msword': '.doc',
  };

  return contentType && extensionMap[contentType] ? extensionMap[contentType] : '';
}