/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import { useInstitution } from '../../../../app/modules/auth/core/InstitutionContext'
import { useEffect } from 'react'

export function HeaderWrapper() {
  const {config, classes} = useLayout()
  const { selectedInstitution } = useInstitution()

  useEffect(() => {
  }
  , [selectedInstitution])

  if (!config.app?.header?.display) {
    return null
  }


  return (
    <div id='kt_app_header' className='app-header h-200px'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'flex-lg-grow-1',
          classes.headerContainer.join(' '),
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTIcon iconName='abstract-14' className=' fs-1' />
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/dashboard' className='d-lg-none'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small.svg')}
                    className='h-30px'
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 '>
            
              {config.layoutType !== 'dark-header' ? (
                <div 
                  className='
                    d-flex align-items-center justify-content-between flex-grow-1 flex-lg-grow-0
                    px-12
                    w-100
                    h-125px
                    bg-primary
                  '
                  /* style={{ width: '100%', display: 'block', backgroundColor: '#68D2DF', height:'150px' }} */
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/logo/logo-nobg.png')}
                    className='h-125px'
                  />
                  <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 flex-lg-grow-0 px-12 w-100 h-125px bg-primary">
                  <p className='h1' style={{color:'white', fontSize:'2.5em'}}>Sistema Nacional de Planificación</p>
                  {/* show selected institution, if dont selected, show nothing */}
                  { selectedInstitution && (
                    <p className='h2' style={{color:'white', fontSize:'1.5em'}}>{selectedInstitution?.name}</p>
                  )}
                  {/* <p className='h2' style={{color:'white', fontSize:'1.5em'}}>({})</p> */}
                  </div>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/logo/escudo-nobg.png')}
                    className='h-125px'
                  />
                </div>
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1 border-bottom border-secondary border-2 pb-5'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch px-8 mt-5'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}
