import {Outlet, Route, Routes} from 'react-router-dom'
import PublicPolicyIndex from './pages/PublicPolicyIndex'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Report from './pages/Report'

const ppBreadCrumbs: Array<PageLink> = [
  {title: 'Home', path: '/dashboard', isSeparator: false, isActive: false},
  {
    title: 'Politicas publicas',
    path: '/politicas-publicas/index',
    isSeparator: false,
    isActive: true,
  },
]

const PublicPolicyPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='index'
        element={
          <>
            <PageTitle breadcrumbs={ppBreadCrumbs}>Politicas publicas</PageTitle>
            <PublicPolicyIndex />
          </>
        }
      />
      <Route
        path='report'
        element={
          <>
            <PageTitle breadcrumbs={ppBreadCrumbs}>Reporte Politicas públicas</PageTitle>
            <Report />
          </>
        }
      />
    </Route>
  </Routes>
)
export default PublicPolicyPage
